import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div``;
export const ListContainer = styled.div`
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 15px 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  min-height: 60px;
  border: 1px solid transparent;
  &.active {
    background: #f8eaec 0% 0% no-repeat padding-box;
    border: 1px solid #cd626e;
  }
  &.has-border {
    border: 1px solid #ccd2e3;
    box-shadow: 0px 0px 5px #ccd2e3;
  }
  &.has-border.active {
    border: 1px solid #cd626e;
    box-shadow: none;
  }
`;
export const Row = styled.div`
  width: 100%;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.top {
    padding-top: 5px;
  }
`;
export const Left = styled.div`
  position: relative;
  & .bold {
    font-weight: bold;
    &.line0 {
        margin-bottom: 5px;
        margin-left: 3px;
      }
  }
  
  &.title {
    display: block;
    font-size: 18px;
    line-height: 21px;
    & .discount {
      color: #bc2e3e;
    }
    
  }
  & .subtitle {
    margin-left: 3px
    margin-top: 3px;
    font-size: 14px;
    @media (max-width: 700px) {
      max-width: 85%;
    }
    @media (max-width: 320px) {
      font-size: 12px;
    }
  }
  & .line1, & .line2 {
    display: block;
    font-size: 14px;
    line-height: 19.6px;
    margin-bottom: 5px;
  }
`;

export const Right = styled.div`
  & .discount {
    display: flex;
    flex-direction: row-reverse;

    & > div {
      display: inline-block;
      color: #fff;
      background-color: #bc2e3e;
      padding: 5px;
      text-align: center;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;
      text-align: right;
    }
  }
  & .bold {
    font-weight: bold;
    font-size: 18px;
    @media ${queries.md} {
      font-size: 16px;
    }
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  & .price-per-day {
    color: #bc2e3e;
  }
  & .price-per-month {
    text-align: right;
    min-width: 100px;
  }
  @media ${queries.md} {
    font-size: 14px;
    width: 40%;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const InputRadio = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #ccd2e3;
  display: block;
  border-radius: 100%;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  &.active {
    border: 1px solid #bc2e3e;
    &:before {
      content: ' ';
      width: 12px;
      height: 12px;
      display: block;
      background-color: #bc2e3e;
      border-radius: 100px;
      margin: 3px;
    }
  }
`;
